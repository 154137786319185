import {observable,runInAction} from 'mobx';
import axios from 'axios';

let mList:any[]=[]
let ws:any
const posez = observable({

    ws:ws,
    order:{
        year:2020,
        month:5,
        day:10,
        orderList:mList,
        ckOrderList:[],
        ckOrderSrl:'',
        listCkState:'noneCk',
        orderCheck:'',
        latitude:'',
        longitude:'',
        val:{},
        state1:0,
        state2:0,
        state3:0,
        comOrder:false,
    },
    cancelMessage:[
        '취소아님',
        '품절',
        '재료부족',
        '배달 불가 지역',
        '영업종료',
        '휴무일',
        '최소금액부족',
        '주문량폭주',
        '기타',
        '접수 시간 초과'
    ],
    menu:{
        bestMenuList:mList,
        normalMenuList:mList,
        info:mList
    },
    complete:{
        list:mList,
        cardCost:0,
        totalCost:0,
        cancelCost:0,
        comYear:0,
        comMonth:0,
        comDay:0,
        
    }, 
    storeList:{
        list:mList,
    },

    // setOrderList(val:[]){
    //     this.order.state2 = 0;
    //     this.order.state3 = 0;
    //     this.order.orderList = val;
    //     this.order.orderList.forEach((val:any)=>{
    //         if(val.pulseState === "1000" || val.state === "4"){
    //             this.order.state3++;
    //         }else if(val.pulseState === "0001"){
    //             this.order.state2++;
    //         }
    //     })
    //     this.order.state1 = val.length;
    // },
    // setOrderListClear(){
    //     this.order.orderList = [];
    // },
    // setOrderChecked(val:string){
    //     this.order.orderCheck = val;
    // },
    // setOrderListMethod(){
    //     let postData ={
    //         'method':'seosanezOrderList',
    //         'data':{
    //             'method':'searchOrderList',
    //             'year':this.order.year,
    //             'month':this.order.month,
    //             'day':this.order.day,
    //         }
    //     }
    //     axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
    //         (response:any)=>{
    //             posez.setOrderList(response.data.result);
    //         }
    //     );
    // },
    setStoreMenu(storeSrl:number){
        let postData ={
            method:'storeInfo',
            data:{
                method:'myStoresInfo',
                storeSrl:storeSrl
            }
        }
        axios.post('https://seosanez.project.wo.tc/yamyam.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response:any)=>{
                var nmList:any[] = []
                var menu:any[] = []
                if(response.data.menuJson.length > 0){
                    nmList = response.data.menuJson
                }
                var bestMenu:any[] = []
                
                for(let stoIdx = 0;stoIdx<nmList.length;stoIdx++){
                    const stoList = nmList[stoIdx].menuList;
                    const storeSrl = nmList[stoIdx].storeSrl;
                    for (let index = 0; index < stoList.length; index++) {
                        const mList = stoList[index].menuList;
                        for(let midx = 0;midx < mList.length;midx++){
                            const mMenu = mList[midx];
                            // console.log('mList',toJS(mMenu))
                            menu.push({storeSrl:storeSrl,menu:mMenu})
                            if(mMenu.bestMenu === '1'){
                                bestMenu.push(mMenu)
                            }
                        }
                    }
                }
                

                runInAction(()=>{
                    this.menu.bestMenuList = bestMenu
                    this.menu.normalMenuList = nmList
                    this.menu.info = response.data.noneListResult
                    this.getIndexDB(menu)
                })
            }
        );
    },
    setStoreInfo(){
        let postData ={
            'method':'storeList',
        }
        axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response:any)=>{
                let stoInfo:any[] = response.data.result;
                // stoInfo.sort(function(a:any,b:any){
                //     return a.strStoreSrl < b.strStoreSrl ? -1 : a.strStoreSrl > b.strStoreSrl ? 1 : 0;
                // })
               

                runInAction(()=>{
                    this.getIndexDBStoreInfo(stoInfo)
                    this.storeList.list = response.data.result;

                    this.storeList.list.sort((a:any,b:any)=>{
                        return Number(a.strStoreSrl) - Number(b.strStoreSrl)
                    })
                })
 
            }
        )
    },

    setOpen(open:number,storeSrl:string){
        let postData ={
            method:'seosanezOpenSet',
            data:{
                storeSrl:storeSrl,
                open:open,
            }
        }
        axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response)=>{
            }
        );
    },
    


    getOrderList(){
        let postData ={
            method:'order',
            data:{
                method:'list'
            }
        }
        axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response:any)=>{ 
                runInAction(()=>{
                    console.log(response.data)
                    this.order.orderList = response.data.result
                }) 
            }
        );
    },

    getCompleteList(){
        let selectedDate = new Date();
        let postData ={
            'method':'order',
            'data':{
                'method': 'completeList',
                'year': selectedDate.getFullYear(),
                'month':selectedDate.getMonth()+1,
                'day':selectedDate.getDate(),
            }
        }
        axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response:any)=>{
                let card:number = 0
                let totalCost:number = 0
                let cancelCost:number = 0
                response.data.forEach((val:any) => {
                    
                    if(Number(val.pulseCancel) > 0){
                        cancelCost = cancelCost + Number(val.cusCost)
                    }else{
                        console.log(val.cusCost)
                        if(Number(val.cardCount) > 0){
                            card = card + Number(val.cusCost)
                        }

                        totalCost = totalCost + Number(val.cusCost)
                    }
                })
                runInAction(()=>{
                    this.complete.list = response.data
                    this.complete.totalCost = totalCost
                    this.complete.cancelCost = cancelCost
                    this.complete.cardCost = card
                    this.complete.comYear =selectedDate.getFullYear()
                    this.complete.comMonth = selectedDate.getMonth()+1
                    this.complete.comDay = selectedDate.getDate()
                })
            }
        );
    },
    getCompleteListSelected(mYear:number,mMonth:number,mDay:number){
        
        let postData ={
            'method':'order',
            'data':{
                'method': 'completeList',
                'year': mYear,
                'month':mMonth,
                'day':mDay,
            }
        }
        axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response:any)=>{
                let card:number = 0
                let totalCost:number = 0
                let cancelCost:number = 0
                response.data.forEach((val:any) => {
                    
                    if(Number(val.pulseCancel) > 0){
                        cancelCost = cancelCost + Number(val.cusCost)
                    }else{
                        if(Number(val.cardCount) > 0){
                            card = card + Number(val.cusCost)
                        }

                        totalCost = totalCost + Number(val.cusCost)
                    }
                })
                runInAction(()=>{
                    this.complete.list = response.data
                    this.complete.totalCost = totalCost
                    this.complete.cancelCost = cancelCost
                    this.complete.cardCost = card
                    this.complete.comYear =mYear
                    this.complete.comMonth = mMonth
                    this.complete.comDay = mDay
                })
            }
        );
    },



    getIndexDBStoreInfo(obj:any[]){
        let name:string = 'pulse_project'
        let version:number = 6
        let db:any = null
        if(window.indexedDB){
            var request = indexedDB.open(name, version);
            request.onupgradeneeded = function(event:any) {
                // IDBDatabase
                db = request.result;

                // var key = "id";
                // var name = 'store_menu';
                // IDBObjectStore

                // var storeMenu = db.createObjectStore(name, { keyPath: key });
                // var storeInfo = db.createObjectStore("store_info", { keyPath: key });


            };
            request.onsuccess = function(e:any){
                db = e.target.result
                // IDBTransaction
                var transaction:any = db.transaction(['store_info'], "readwrite")
                // IDBObjectStore
                var objectStore = transaction.objectStore('store_info')
                var delRes:any = objectStore.clear()
                delRes.onsuccess = function(delError:any){
                    obj.forEach((val,idx)=>{
                        var mRes = objectStore.add({"id":idx,"store":val})
                        mRes.onsuccess = function(aa:any){
                            // event.target.result == customerData[i].ssn
                        }
                    })
                }
            }
        }
    },
    getIndexDB(obj:any[]){
        let name:string = 'pulse_project'
        let version:number = 6
        let db:any = null
        if(window.indexedDB){
            var request = indexedDB.open(name, version);
            request.onupgradeneeded = function(event:any) {
                // IDBDatabase
                db = request.result;
                console.log(db)
                // console.log('db',db)
                var key = "id";
                var name = 'store_menu';
                // IDBObjectStore
            
                var storeMenu = db.createObjectStore(name, { keyPath: key });
                var storeInfo = db.createObjectStore("store_info", { keyPath: key });

                // var indexName = 'by_name';
                // var keyPath = 'storeName';
                // // IDBIndex
                // // var index = storeMenu.createIndex(indexName, keyPath);
                // var obj = {
                //     [key]: 1,
                //     [keyPath]: {storeName:'ccccc',version:'12'}
                // }
                // storeMenu.put(obj);
                // obj = {
                //     [key]: 2,
                //     [keyPath]: {storeName:'aaaa',version:'1'}
                // }
                // storeMenu.put(obj);


            };
            request.onsuccess = function(e:any){
                db = e.target.result
                // IDBTransaction
                var transaction:any = db.transaction(['store_menu'], "readwrite")
                // IDBObjectStore
                var objectStore = transaction.objectStore('store_menu')
                var delRes:any = objectStore.clear()
                delRes.onsuccess = function(delError:any){
                    // console.log(obj)
                    obj.forEach((val,idx)=>{
                        var mRes = objectStore.add({"id":idx,"menu":val.menu,"storeSrl":val.storeSrl})
                        mRes.onsuccess = function(aa:any){
                            // console.log('db add success',aa.target.result)
                            // event.target.result == customerData[i].ssn
                        }
                    })
                }
            }
        }
    },
    setSocket(message:any){
        runInAction(()=>{
            this.ws = message
        })
    }

})

export default posez