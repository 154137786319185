import { action, makeObservable, observable, runInAction } from 'mobx'
import axios from 'axios'
import wsconfig from './wsconfig'
import posez from './posez'

class WebSocketClient {
    webSocket:any = new WebSocket("wss://pos.project.wo.tc/websocket");
    msg:String = '';
    notification:boolean = false;
    notificationList:Array<any> =[];
    timer:any = {};
    message:any = "";
    
    connection:boolean =false;

    constructor() {
        makeObservable(this, {
            webSocket: observable,
            msg: observable,
            notification:observable,
            notificationList:observable,
            timer:observable,
            connection:observable,
            message:observable,
            msgLoad:action,
            addNotificationList:action,
            setConnection:action,
            onMessage:action
        })

        this.webSocket.onopen = function(message:any){
            var mToken = Math.floor(Math.random() * 100000);
            var authData:any = {storeSrl:'1',method:'auth',token:mToken}
            runInAction(()=>{
                wsconfig.setConnection(true)
            })
            message.currentTarget.send(JSON.stringify(authData))
            let postData ={
                'method':'socketAuth',
                'data':{
                    'method':'auth',
                    'token': mToken,
                }
            }
            axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
                (response:any)=>{
                    if(response.data.returnCode ==="0000"){
                        let memberSrl:number = Number(response.data.session.memberSrl)
                        if( memberSrl> 0){        
                            posez.setStoreMenu(memberSrl)
                            posez.setStoreInfo()
                        }
                    }
                }
            );

        }
        this.webSocket.onclose = function(message:any){
            runInAction(()=>{
                console.log('socket out')
                wsconfig.setConnection(false)
            })
        }
        this.webSocket.onmessage = this.onMessage
    }
    setConnection(val:boolean){
        this.connection = val;
    }
    reConnection(val:boolean){
        if(val){
            this.webSocket = new WebSocket("wss://pos.project.wo.tc/websocket")
            this.webSocket.onopen = function(message:any){
                var mToken = Math.floor(Math.random() * 100000);
                var authData:any = {storeSrl:'1',method:'auth',token:mToken}
                runInAction(()=>{
                    wsconfig.setConnection(true)
                })
                message.currentTarget.send(JSON.stringify(authData))
                let postData ={
                    'method':'socketAuth',
                    'data':{
                        'method':'auth',
                        'token': mToken,
                    }
                }
                axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
                    (response:any)=>{
                        console.log('authStep',response.data)
                        if(response.data.returnCode ==="0000"){
                            let memberSrl:number = Number(response.data.session.memberSrl)
                            if( memberSrl> 0){        
                                posez.setStoreMenu(memberSrl)
                                posez.setStoreInfo()
                            }
                        }
                    }
                );
            }
            this.webSocket.onclose = function(message:any){
                runInAction(()=>{
                    wsconfig.setConnection(false)
                })
            }
            this.webSocket.onmessage = this.onMessage

        }else{
            runInAction(()=>{
                this.webSocket.close()
            })
        }
        
    }
    addNotificationList(mMessage:any,mOrderSrl:number,){
        if(this.notificationList.length > 3){
            this.notificationList.push({message:mMessage,orderSrl:mOrderSrl})
            this.notificationList.shift()
        }else{
            this.notificationList.push({message:mMessage,orderSrl:mOrderSrl})
        }
    }
    msgLoad(message:any){
        console.log('msgLoad',message.data);
        var data = JSON.parse(message.data)
        runInAction(()=>{
            console.log('msg',data.message)
            this.msg = data.message
        })
        
    }
    onMessage(msg:any){

        runInAction(()=>{
            wsconfig.message = msg.data
        })
        // var data = JSON.parse(message.data)
        
        // if(data.sendData !==undefined){
        //     if(data.sendData.method === 'quickOrderInsert' || data.sendData.method === 'orderComplete'){
        //         posez.setOrderListMethod();
        //         if(posezSetting.config.alarmSound){
        //             s.play()
        //         }
        //         if(posezSetting.config.alarmMsg){
        //             var noti = new Notification('신규주문',{body: data.message,sound:current})
        //             noti.onclick = function(e){
        //                 e.preventDefault();
        //                 window.self.focus();
        //                 this.close();
        //             }
        //         }
        //     }else if(data.sendData.method === 'chat' ){
        //         // runInAction(()=>{
                    
        //         //     var val = {message:data.message, regdate:'2020-06-19 12:12:12',member:'2'}
        //         //     chat.setAddChatList(val)
        //         //     posez.setOrderChecked(val)
        //         // })
        //     }
        // }
    }
}

const webSocketClient = new WebSocketClient()
export default webSocketClient

	