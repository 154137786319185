import { observer } from 'mobx-react';
import React, { Suspense, useEffect } from 'react'
import {Route,Switch} from 'react-router-dom'
import './App.css';
import PosezOrderList from './posez/PosezOrderList'
import posez from './store/posez'
import wsconfig from './store/wsconfig'
import posezSetting from './store/posezSetting'
import current from "./resource/current.mp3"
// import packageJson from '/package.json';

const PosezNewOrder = React.lazy(()=>import('./posez/PosezNewOrder'))
const Login = React.lazy(()=>import('./posez/Login'))
const PosezSetting = React.lazy(()=>import('./posez/PosezSettingtest'))
const PosezManager = React.lazy(()=>import('./posez/PosezManager'))
const ReceiptSetting = React.lazy(()=>import('./posez/ReceiptSetting'))
const Calculate = React.lazy(()=>import('./posez/Calculate'))



const s = new Audio(current);
const App = observer(()=> {

    useEffect(()=>{

    // console.log(packageJson.name);
        posezSetting.configLoad()
    },[])
    

    useEffect(()=>{
        try{
            if(wsconfig.message !==""){
                var data = JSON.parse(wsconfig.message)
                if(data.sendData !==undefined){
                    if(data.sendData.method === 'quickOrderInsert' || data.sendData.method === 'orderComplete'){
                        posez.setOrderListMethod()
                        if(posezSetting.config.alarmSound){
                            s.play()
                        }
                        console.log(posezSetting.config.alarmMsg)
                        if(posezSetting.config.alarmMsg){
                            var noti = new Notification('신규주문',{body: data.message,sound:current})
                            noti.onclick = function(e){
                                e.preventDefault()
                                window.self.focus()
                                this.close();
                            }
                        }
                    }else if(data.sendData.method === 'chat' ){
                        // runInAction(()=>{
                            
                        //     var val = {message:data.message, regdate:'2020-06-19 12:12:12',member:'2'}
                        //     chat.setAddChatList(val)
                        //     posez.setOrderChecked(val)
                        // })
                    }
                }
            }
        }catch(e){
            console.log(e)
        }
        
    },[wsconfig.message])

    return (
        <div className="App">
            <Suspense fallback={<div>loading</div>} >
                <Switch>
                    <Route exact path="/" component={PosezOrderList} />
                    <Route exact path="/login" component={Login} />
                    <Route path="/newOrder" component={PosezNewOrder} /> 
                    <Route path="/setting" component={PosezSetting} />
                    <Route path="/manager" component={PosezManager} />
                    <Route path="/receiptSetting" component={ReceiptSetting} />
                    <Route path="/calculate" component={Calculate}/>
                    <Route >
                        <PosezOrderList />
                    </Route>
                </Switch>
            </Suspense>
        </div>
    );
})

export default App;
