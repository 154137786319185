import { action, makeObservable, observable, runInAction } from 'mobx'
// import wsconfig from './wsconfig'
import printws from './printws'

class PrintWs {
    webSocket:any = new WebSocket("ws://localhost:9993/ws");
    msg:String = '';
    timer:any = {};
    connection:boolean =false;
    message:any = "";

    constructor() {
        makeObservable(this, {
            webSocket: observable,
            msg: observable,
            message:observable,
            timer:observable,
            connection:observable,
            reConnection:action,
            setConnection:action,
            onMessage:action,
        })
        this.webSocket.onopen = function(message:any){
            runInAction(()=>{
                printws.setConnection(true)
            })
            message.currentTarget.send("show me the money")
        }
        this.webSocket.onclose = function(message:any){
            runInAction(()=>{
                printws.setConnection(false)
            })
        }
        this.webSocket.onmessage = this.onMessage
    }
    reConnection(val:boolean){
        if(val){
            runInAction(()=>{
                this.webSocket = new WebSocket("ws://localhost:9993/ws")
                this.webSocket.onopen = function(message:any){
                    printws.setConnection(true)
                    message.currentTarget.send("show me the money")
                }
                this.webSocket.onclose = function(message:any){
                    printws.setConnection(false)
                }
                this.webSocket.onmessage = this.onMessage
            })
        }else{
            runInAction(()=>{
                this.webSocket.close()
            })
        }
    }
    setConnection(val:boolean){
        this.connection = val;
    }

    onMessage(message:any){
        runInAction(()=>{
            printws.message = message.data
        })
        // console.log('print',message.data)
    }
}

const printConnection = new PrintWs()
export default printConnection

	