import {observable,runInAction} from 'mobx';


type CategoryItem = {categoryName:string, categorySrl:number, menuList:any[]}
let list:CategoryItem[] = []



type Setting = {
    tmpCateIdx:number,
    tmpStoIdx:number,
    tmpMenuIdx:number,
    tmpCateList:CategoryItem[],
    tmpStoList:any[],
    tmpStoMenuList:any[],
    tmpMenuList:any[],
    menuList:any[],
    storeList:any[],
    category:any[],
    categoryMenuList:any[],
    defaultStoreSrl:number,
    defaultStoreName:string,
    alarmSound:boolean,
    alarmMsg:boolean,
    version:string,
}
type Conf = {
    alarmSound:boolean,
    alarmMsg:boolean
}

let st:Setting = {
    tmpCateIdx:0,
    tmpStoIdx:0,
    tmpMenuIdx:0,
    tmpCateList:list,
    tmpStoList:[],
    tmpStoMenuList:[],
    tmpMenuList:[],
    menuList:[],
    storeList:[],
    category:[],
    categoryMenuList:[],
    defaultStoreSrl:0,
    defaultStoreName:'aaa',
    alarmSound:true,
    alarmMsg:true,
    version:"1.0.0",
}
let conf:Conf = {
    alarmSound:true,
    alarmMsg:true,
}

let orderMenuList:any[]=[];

const posezSetting = observable({

    setting:st,
    config:conf,
    order:{
        orderMenuList:orderMenuList,
        totalCost:0,
        deliveryCost:0,
    },
    settingSave(){
        window.localStorage.setItem("posConfig",JSON.stringify(this.config))
    },
    configLoad(){
        let conf = JSON.parse(window.localStorage.getItem("posConfig")||JSON.stringify({message:'error'}))
        runInAction(()=>{
            this.config.alarmSound = conf.alarmSound
            this.config.alarmMsg = conf.alarmMsg
        })
    },
    onLoad(){
        if(this.setting.menuList.length > 0 ){
        }else{
            let ct = JSON.parse(window.localStorage.getItem("ezSetting")||JSON.stringify({message:'error'}))
            if(ct.message==='error'){
                console.log('posezSetting - cartLoad error')
            }else{
                this.setting.storeList = ct;
                if(ct.length > 0 ){
                    this.setting.menuList = ct[0].menuList;
                    this.setting.category = ct[0].category;
                    if(ct[0].category.length === 0){
                        this.setting.categoryMenuList = [];
                    }else{
                        this.setting.categoryMenuList = ct[0].category[0].menuList;
                    }
                    
                }
            }
        }
        if(this.setting.defaultStoreSrl === 0){
            let ct = JSON.parse(window.localStorage.getItem("posSetting")||JSON.stringify({message:'error'}))
            this.setting.defaultStoreSrl = Number(ct.defaultStoreSrl);
            this.setting.defaultStoreName = ct.defaultStoreName;
        }
    },
    onLoadIndexedDB(storeSrl:string){
        let name:string = 'pulse_project'
        let version:number = 6
        let db:any = null
        if(window.indexedDB){
            var request = indexedDB.open(name, version)
            request.onsuccess = function(e:any){
                // console.log('dbLoadSuccess')
                db = e.target.result
                var transaction:any = db.transaction(['store_menu'], "readwrite")
                var objectStore = transaction.objectStore('store_menu')
                var res = objectStore.getAll()
                res.onsuccess = function(e:any){
                    // console.log('show db',e.target.result)

                    var menu = e.target.result
                    var mList:any[] = []
                    if(storeSrl.length ===0){
                        menu.forEach((element:any) => {
                            mList.push(element.menu)
                        })
                    }else{
                        menu.filter((val:any)=>Number(val.storeSrl)===Number(storeSrl)).forEach((element:any) => {
                            mList.push(element.menu)
                        })
                    }
                    
                    runInAction(()=>{
                        posezSetting.setting.menuList = mList
                    })
                }

            }
        }
    },
    // onLoadStoreIndexedDB(){
    //     let name:string = 'pulse_project'
    //     let version:number = 5
    //     let db:any = null
    //     if(window.indexedDB){
    //         var request = indexedDB.open(name, version)
    //         request.onsuccess = function(e:any){
    //             db = e.target.result
    //             var transaction:any = db.transaction(['store_info'], "readwrite")
    //             var objectStore = transaction.objectStore('store_info')
    //             var res = objectStore.getAll()
    //             res.onsuccess = function(e:any){
    //                 console.log('show db',e.target.result)

    //                 // var menu = e.target.result
    //                 // var mList:any[] = []
    //                 // if(storeSrl.length ===0){
    //                 //     menu.forEach((element:any) => {
    //                 //         mList.push(element.menu)
    //                 //     })
    //                 // }else{
                        
    //                 //     menu.filter((val:any)=>val.storeSrl===storeSrl).forEach((element:any) => {
    //                 //         mList.push(element.menu)
    //                 //     })
    //                 // }
                    
    //                 // runInAction(()=>{
    //                 //     posezSetting.setting.menuList = mList
    //                 // })
    //             }

    //         }
    //     }
    // },
    onSettingLoad(){
        let ct = JSON.parse(window.localStorage.getItem("ezSetting")||JSON.stringify({message:'error'}))
        if(ct.message==='error'){
            console.log('posezSetting - cartLoad error')
        }else{
            this.setting.storeList = ct;
            if(ct.length > 0 ){
                this.setting.tmpStoMenuList = ct[0].menuList;
                this.setting.tmpCateList = ct[0].category;
                if(ct[0].category.length === 0){
                    this.setting.tmpMenuList = [];
                }else{
                    this.setting.tmpMenuList = ct[0].category[0].menuList;
                }   
            }
        }
        
        if(this.setting.defaultStoreSrl === 0){
            let ct = JSON.parse(window.localStorage.getItem("posSetting")||JSON.stringify({message:'error'}))
            this.setting.defaultStoreSrl = Number(ct.defaultStoreSrl);
            this.setting.defaultStoreName = ct.defaultStoreName;
        }
    },
    setStoreList(storeList:[]){
        this.setting.storeList = storeList;
    },
    setCategory(idx:number){
        this.setting.categoryMenuList = this.setting.category[idx].menuList;
    },
    setAddOrderMenu(menu:any){
        var tt:number = 0;
        tt = Number(menu.menuCost);
        var ck:boolean = true;
        this.order.orderMenuList.forEach((val,idx)=>{
            tt = tt+ Number(val.menuCost)*Number(val.quantity);
            if(val.menuSrl === menu.menuSrl){
                if(val.defaultName === menu.defaultName){
                    this.order.orderMenuList[idx].quantity = Number(val.quantity) + 1;
                    ck = false;
                }
            }
        })
        if(ck){
            menu.quantity = 1;
            this.order.orderMenuList.push(menu);
        }
        this.order.totalCost = tt;
    },
    setTotalCost(){
        var tt:number = 0;
        this.order.orderMenuList.forEach((val,idx)=>{
            val.menuOptionGroup?.forEach((element:any) => {
                element.optionList.forEach((opVal:any) => {
                    tt = tt + Number(opVal.optionCost)
                });
            });
            tt = tt+ Number(val.menuCost)*Number(val.quantity);
        })
        tt = tt+ this.order.deliveryCost;
        this.order.totalCost = tt;
    },
    setOrderClear(){
        this.order.orderMenuList = [];
        this.order.totalCost = 0;
    },
    addCategory(){
        var srl:number = 0;
        this.setting.tmpCateList.forEach((val,idx)=>{
            if(Number(val.categorySrl) >= srl){
                srl = Number(val.categorySrl);
            }
        })
        runInAction(()=>{
            let item : CategoryItem = {categoryName:'메뉴이름',categorySrl:(srl+1),menuList:[]}
            this.setting.tmpCateList.push(item)
        })
    },
    ezSave(){
        this.setting.storeList.forEach((val:any,idx:number)=>{
            if(Number(val.storeSrl) === Number(this.setting.defaultStoreSrl)){
                runInAction(()=>{
                    this.setting.tmpCateList[this.setting.tmpCateIdx].menuList = this.setting.tmpMenuList;
                    this.setting.storeList[0].category = this.setting.tmpCateList;

                    window.localStorage.setItem("ezSetting",JSON.stringify(this.setting.storeList))
                })
            }
        })
    }
})

export default posezSetting