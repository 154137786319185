import { useEffect,useRef,useState } from 'react'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useHistory} from 'react-router-dom'
import imgLogo from '../resource/pulselogo_323232.svg'
import imgSetting from '../resource/posez/setting.svg'
// import imgChat from '../resource/posez/chat.svg'
import imgOnOff from '../resource/posez/onoff.svg'

import imgOn from '../resource/posez/on.svg'
import imgOff from '../resource/posez/off.svg'
import imgCancel from '../resource/cancel.svg'
// import imgGraph from '../resource/posez/graph.svg'
import wsconfig from '../store/wsconfig'
import {observer} from 'mobx-react'
import posez from '../store/posez'
import posezSetting from '../store/posezSetting'
import { runInAction } from 'mobx'
import './posez.css'
// import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps'; // 패키지 불러오기
import Dialog from '@material-ui/core/Dialog'

import './posezorderlist.css'
import printws from '../store/printws'
import { Snackbar } from '@material-ui/core'

import imgRadioOn from '../resource/radio_323232.svg'
import imgRadioOff from '../resource/radio_dcdcdc.svg'

 
 
const btnOn = {
    marginLeft:'7px',backgroundColor:'#5d5d5d',color:'#ffffff',border:'1px solid #323232'
 }

 const btnOff = {
     marginLeft:'7px',backgroundColor:'#ffffff',border:'1px solid #969696'
 }

   



const PosezOrderList = observer((props)=>{
    
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedMonth, setSelectedMonth] = useState(new Date())
    const [select,setSelect] = useState('date');
    const refDate = useRef();
    const refMonth = useRef();

 
    const [val,setVal] = useState(0)


    const [dialogYear,setDialogYear] = useState(false);
    const [dialogQuarter,setDialogQuarter] = useState(false);
    const [ckYear,setCkYear] = useState('2022');
    const [ckQuarter,setCkQuarter] = useState('1분기');
 
    const sortSrl  = {result:[{srl:'0'},{srl:'1'},{srl:'4'},{srl:'5'}]}
    const [pageSort,setPageSort] = useState('0');
    const [state,setState] = useState('0');
    const [setting,setSetting] = useState(false);
    const [onoff,setOnOff] = useState(false);
    const [acceptOpen,setAcceptOpen] = useState(false);
    const [refusalOpen,setRefusalOpen] = useState(false);
    const [dMinute,setDMinute] = useState(20)
    const [rReason,setRReason] = useState(1)
    const [completeOpen,setCompleteOpen] = useState(false)
    const [toast,setToast] = useState(false)
    const [toastMsg,setToastMsg] = useState('')

    const [ckCalculateStore,setCkCalculateStore] = useState(-1)

    const handleOnOff = () => {
        posez.setStoreInfo()
        setOnOff(true)
    }


     

    useEffect(()=>{

        posez.getOrderList();
        setSelect('date')
        setSelectedDate(new Date());

        if(pageSort==='4'){
            document.getElementById('input_date').valueAsDate = new Date(Date.UTC(selectedDate.getFullYear(),selectedDate.getMonth(),selectedDate.getDate()))
            document.getElementById('input_month').value = new Date().toISOString().slice(0, 7) 
            console.log('날짜',new Date().toISOString().slice(0, 7))
 
        } 
        
    },[pageSort])

    
    const handleClosed = (event, reason) => { 
        if (reason === 'clickaway') {
            return;
        }
        setToast(false)
        
    };

    



    const handleAccept = ()=>{
        if(state==="0000"){
            setAcceptOpen(true)
        }else if(state==="0001" || state==="0999"){
              setCompleteOpen(true)
        }
    }
    
    const handleSetMinute=(time)=>{
        setDMinute(Number(time))
    }

    const handleSetRReason=(reason)=>{
        setRReason(reason)
    }

  
    const handleSetConfirm = ()=>{
        if(Number(posez.order.ckOrderSrl) > 0){
            
            if(posez.order.ckOrderList.pulseState === '0000'){
                let postData ={
                    method:'seosanezPulseOrderConfirm',
                    data:{
                        orderSrl:posez.order.ckOrderSrl,
                        storeSrl:posez.order.ckOrderList.storeSrl,
                        delayTime:dMinute
                    }
                }
                axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
                    (response)=>{
                        if(response.data.returnCode === '0000') {
                            runInAction(()=>{
                            posez.order.ckOrderList.pulseState = response.data.pulseState
                            })
                            setAcceptOpen(false)
                            setToast(true)
                            setToastMsg('접수되었습니다')
                             posez.getOrderList()
                        }
                        
                    }
                );

            }else if(posez.order.ckOrderList.pulseState === '0001'){
                let postData2 ={
                    method:'updateOrderComplete',
                    data:{
                        orderSrl:posez.order.ckOrderSrl,
                        orderType:"1000"
                    }
                }
                axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData2),{ withCredentials: true}).then(
                    (response)=>{
                        console.log('완료',response.data)
                        if(response.data.noneListResult.message === '변경사항 저장에 성공하였습니다.') {
                            runInAction(()=>{
                            posez.order.ckOrderList.pulseState = response.data.pulseState
                            })
                            setCompleteOpen(false)
                            setToast(true)
                            setToastMsg('완료되었습니다')
                             posez.getOrderList()
                    
                        }
                    }
                );
 
                
            }
          
        }
    }

    const handleSetRefusal = ()=>{
      
        let postData ={
            method:'order',
            data:{
                method: 'orderCancel',
                orderSrl:posez.order.ckOrderSrl,
                cancelType:rReason,
                cancelMemo:'',
                
            }
        }
        axios.post('https://seosanez.project.wo.tc/seosanez.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response)=>{
                console.log('거부',response.data)
                    if(response.data.returnCode === '0000') {
                        runInAction(()=>{
                        posez.order.ckOrderList.pulseState = response.data.pulseState
                        })
                        setRefusalOpen(false)
                        setToast(true)
                        setToastMsg('거부되었습니다')
                        posez.getOrderList()
            
                }
            }
        );

    }




    const handleSetOrderModify = ()=>{
        history.push({pathname:"/newOrder",state:{result:{...posez.order.val},newOrder:false}})
    }
 

    const unixTimeStamp=(dTime)=>
    {
        return Math.floor((dTime*1000-(new Date().getTime()+1000*3600*9)) / 60000)
    }
 
    const unixTimeChange = (date) => 
    {
        return (new Date(date).valueOf());
    }

    const pulseTime = (pulseDelay,deadline,pulseConTime,regdate) => {
        return (
                100
                -
                ((unixTimeStamp(pulseDelay)) //남은시간
                /
                ((unixTimeStamp(pulseDelay)-unixTimeStamp(deadline)) - Math.floor((unixTimeChange(pulseConTime)-unixTimeChange(regdate))/60000)))  //전체시간
                *
                100)
    }


    
    const handlePageSort = (val) => {
        setPageSort(val)
      
        if(val==='4'){
            runInAction(()=>{
                posez.order.comOrder=true
            })
        }else{
            runInAction(()=>{
                posez.order.comOrder=false
            })
        }

        runInAction(()=>{
            posez.order.ckOrderSrl =  ''
        })
        
        if(Number(val)===0){
            setState('0000')

            // runInAction(()=>{
            //     posez.order.ckOrderSrl =   posez.order.orderList.filter(vval=>vval.pulseState==='0000')[0]?.orderSrl
            // })
          
        }else if(Number(val)===1){
            setState('0001')

            // runInAction(()=>{
            //     posez.order.ckOrderSrl =   posez.order.orderList.filter(vval=>vval.pulseState==='0001')[0]?.orderSrl
            // })
        }else{
            setState('1000')

            // runInAction(()=>{
            //     posez.order.ckOrderSrl =   posez.order.orderList.filter(vval=>vval.pulseState==='1000')[0]?.orderSrl
            // })
        }
    }

    // console.log(state,'state')
    // console.log(pageSort,'pageSort')
    
    const handleAlarmMsg = (val) => {
        runInAction(()=>{
            posezSetting.config.alarmMsg = val
            posezSetting.settingSave()
        })
    }
    const handleAlarmSound = (val) => {
        runInAction(()=>{
            posezSetting.config.alarmSound = val
            posezSetting.settingSave()
        })
    }


    
    const handleLogout = async()=>{
        let postData = {
            'method':'logout',
        }
        axios.post('https://seosanez.project.wo.tc/yamyam.php',JSON.stringify(postData),{ withCredentials: true}).then(
            (response)=>{
                if(!response.data.isLogin){
                    history.push({pathname:'/login'})
                }
            }
        );
    }

    const handleSetOpen=(val)=>{
        var op = 1
        var storeSrl = val.strStoreSrl 
        runInAction(()=>{
            val.open = op
        })
        
        posez.setOpen(op,storeSrl)
    }

    const handleSetClosed=(val)=>{
        var op = 0
        var storeSrl = val.strStoreSrl 
        runInAction(()=>{
            val.open = op
        })
        
        posez.setOpen(op,storeSrl)
    }


    const handleConnectPrint = (val)=>{
        printws.reConnection(val)
    }
    const handleConnectServer = (val)=>{
        wsconfig.reConnection(val)
    }

    useEffect(()=>{
        handlePageSort('0')
        posezSetting.setOrderClear()
        
    },[])
   


    
 const handleDateChange = (ref,sc) => {
        setSelect(sc)
        if(sc==='date'){
            setSelectedDate(new Date(ref));
        }else{
            setSelectedMonth(new Date(ref));   
        }
} 


useEffect(()=>{
    if(select==='date'){
        let mDay = selectedDate.getDate()
        let mMonth = selectedDate.getMonth()+1
        let mYear = selectedDate.getFullYear() 
        posez.getCompleteListSelected(mYear,mMonth,mDay)
    } 
},[selectedDate])


useEffect(()=>{
    if(select==='month'){
        let mDay = 0
        let mMonth = selectedMonth.getMonth()+1
        let mYear = selectedMonth.getFullYear() 
        posez.getCompleteListSelected(mYear,mMonth,mDay)
    }
},[selectedMonth])

 
 
 


  

        const Calculate = observer(()=>{

           
        
            return(
                <div style={{width:'100%',minWidth:'100%',maxWidth:'100%',
                color:'#323232',fontSize:17,display:'flex',flexDirection:'row',boxSizing:'border-box',overflow:'hidden'}}>
                    
                    <div className='calculateLeft'>
 
                            <div className='calculateLeftList'>

                                <div onClick={()=>setCkCalculateStore(-1)}>
                                    <img src={ckCalculateStore===-1?imgRadioOn:imgRadioOff}/>
                                    전체보기
                                </div>

                                {posez.storeList.list.map((val,idx)=>
                                    <div key={idx} onClick={()=>setCkCalculateStore(idx)}>
                                        <img src={ckCalculateStore===idx?imgRadioOn:imgRadioOff}/>
                                        {val.storeName}
                                    </div>
                                )}
                            </div>
                    
                    </div>
                    <div className='calculateRight'>
                        <div className='calculateRightLayout'>
                            <div className='calculateTop'> 
                                <span>{ckYear}년도 {ckQuarter} {ckQuarter==='1분기'?'(1~3월)':ckQuarter==='2분기'?'(4~6월)':ckQuarter==='3분기'?'(7~9월)':'(10~12월)'} </span>
                                <span>
                                    <div onClick={()=>setVal(0)} className={val===0?'valOn':'valOff'}>매출</div>
                                    <div onClick={()=>setVal(1)} className={val===1?'valOn':'valOff'}>매입</div>
                                </span> 
                            </div> 

                            <div style={{height:60,minHeight:60,maxHeight:60}}/>
                            
                            {val===0?
                            <div>
                                <div className='calculateList'>
                                    <span  style={{fontWeight:700,fontSize:20}}>
                                        <div>전체매출(수익)</div>
                                        <div>0건</div>
                                    </span>
                                    <span>
                                        <div>매출</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>부가세</div>
                                        <div>0원</div>
                                    </span>
                                    <span  style={{color:'#ff0000'}}>
                                        <div>총액</div>
                                        <div>0원</div>
                                    </span>
                                </div>

                                <div className='calculateList'>
                                    <span >
                                        <div>선결제매출</div>
                                        <div>0건</div>
                                    </span>
                                    <span>
                                        <div>매출</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>부가세</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>총액</div>
                                        <div>0원</div>
                                    </span>
                                </div>

                                <div className='calculateList'>
                                    <span>
                                        <div >현금매출(현장결제)</div>
                                        <div>0건</div>
                                    </span>
                                    <span>
                                        <div>매출</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>부가세</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>총액</div>
                                        <div>0원</div>
                                    </span>
                                </div>


                                <div className='calculateList'>
                                    <span>
                                        <div>카드매출(현장결제)</div>
                                        <div>0건</div>
                                    </span>
                                    <span>
                                        <div>매출</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>부가세</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>총액</div>
                                        <div>0원</div>
                                    </span>
                                </div>
                            </div>
                            :
                            <div>
                                 <div className='calculateList'>
                                    <span  style={{fontWeight:700,fontSize:20}}>
                                        <div>전체매입(지출)</div>
                                       
                                    </span>
                                    <span>
                                        <div>공급가액</div>
                                        <div>08원</div>
                                    </span>
                                    <span>
                                        <div>세액</div>
                                        <div>0원</div>
                                    </span>
                                    <span style={{color:'#ff0000'}}>
                                        <div>총액</div>
                                        <div>0원</div>
                                    </span>
                                </div>

                           
                                <div className='calculateList'>
                                    <span>
                                        <div>곱빼기 이용권 구매</div>
                                       
                                    </span>
                                    <span>
                                        <div>공급가액</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>세액</div>
                                        <div>0원</div>
                                    </span>
                                    <span>
                                        <div>총액</div>
                                        <div>0원</div>
                                    </span>
                                </div>
                            </div>
                            }
                            
                        </div>
                                        
                    </div>
                </div>
            );
        })
        

        const OrderList = observer(()=>{

            const handleReception = (val) => {
                runInAction(()=>{
                    posez.order.ckOrderList={...val};
                    posez.order.ckOrderSrl=val.orderSrl;
                    posez.order.listCkState='Ck';
                    posez.order.latitude = val.cusLatitude;
                    posez.order.longitude = val.cusLongitude;
                    posez.order.val = val;
                    posez.order.ckOrderList.pulseMenu = JSON.parse(val.pulseMenu)
                    posez.order.comOrder=false
                })
 
            }
        
            const handleComOrder = () => {
        
                runInAction(()=>{
                    posez.order.ckOrderSrl='';
                    posez.order.comOrder=true
                })
            }
            return(
                <div style={{width:'100%',color:'#323232',fontSize:17,}}>

                    {pageSort==='4'?
                        <div onClick={()=>handleComOrder()} className={posez.order.comOrder?"orderListCk":"orderList"}style={{paddingTop:20,paddingBottom:20,fontWeight:700,alignItems:'center'}}>
                           <div>{select==='month'?selectedMonth.getFullYear()+'-'+(selectedMonth.getMonth()+1):selectedDate.getFullYear()+'-'+(selectedDate.getMonth()+1)+'-'+selectedDate.getDate()}</div>   
                           <div style={{display:'flex',flex:1,justifyContent:'flex-end',textAlign:'right'}}>{posez.complete.list.length}건 (총 {(posez.complete.totalCost).toLocaleString()}원) {console.log('완료리스트',posez.complete)}</div>
                        </div>
                        :<></>
                    }
                    {pageSort==='4'?
                    posez.complete.list.map((val,idx)=>(
                        <div className={posez.order.ckOrderSrl===val.orderSrl?"orderListCk":"orderList"}
                             key={val.orderSrl} onClick={()=>handleReception(val)}>

                            <div className="leftBox" >
                                
                                <span>{val.stoName}</span>

                                <span>[{val.pulseCode.length > 2?val.pulseCode:''}]</span>

                                <span>
                                {JSON.parse(val.pulseMenu).menuList.length > 0?
                                    JSON.parse(val.pulseMenu).menuList.length === 1?
                                        (JSON.parse(val.pulseMenu).menuList[0].menuName)
                                        :
                                        (JSON.parse(val.pulseMenu).menuList[0].menuName)
                                        +  ' 외 ' + ((JSON.parse(val.pulseMenu).menuList.length)-1)+'개'
                                        :''} 

                                </span>

                                <div className='cusOrderInfo'>
                                    
                                    <div style={{color:posez.order.ckOrderSrl===val.orderSrl?'':'#aaaaaa'}}>
                                        {val.cusAddress.length>0?val.cusAddress + val.cusOtherAddress
                                        :'포장'}
                                    </div>
                                </div>
                                
                            </div>

                            <div className='rightBox'>    
                                <div>{val.regdate.substring(5,7)}월{val.regdate.substring(8,10)}일 {val.regdate.substring(11,16)}</div>
                                <span className={val.pulseCancel>0?"btnOrderCompleteCancel":"btnOrderComplete"}>{val.pulseCancel>0?'취소':'완료'}</span>
                            </div>
                        </div>
                    )):
                    posez.order.orderList.filter(vval=>vval.pulseState===state || vval.pulseState === '0999').map((val,idx)=>(
                    <div className={posez.order.ckOrderSrl===val.orderSrl?"orderListCk":"orderList"} key={val.orderSrl} onClick={()=>handleReception(val)}>
                        <div className="leftBox" >
                            <span>{val.storeName}</span>
                            <span>[{val.pulseCode.length > 2?val.pulseCode:''}]</span>
                            <span>
                            {JSON.parse(val.pulseMenu).menuList.length > 0?
                                JSON.parse(val.pulseMenu).menuList.length === 1?
                                    (JSON.parse(val.pulseMenu).menuList[0].menuName)
                                    :
                                    (JSON.parse(val.pulseMenu).menuList[0].menuName)
                                    +  ' 외 ' + ((JSON.parse(val.pulseMenu).menuList.length)-1)+'개'
                                    :''} 

                            </span>

                            <div className='cusOrderInfo'>
                                
                                <div style={{color:posez.order.ckOrderSrl===val.orderSrl?'':'#aaaaaa'}}>
                                    {val.cusAddress.length>0?val.cusAddress + val.cusOtherAddress
                                    :'포장'}
                                </div>
                            </div>
                        </div>

                        <div className='rightBox'>    
                            <div>{val.regdate.substring(11,16)}</div>


                            {/* (여기!!) */}
                            
                            {val.state==='0' && val.pulseState==='0000'?
                            <span className="btnOrder">주문</span>:<></>}

                            {val.pulseState==="0001"?
                            <span className="btnOrder-proceed">
                                
                               { <span className="order-button" style={{color:unixTimeStamp(val.pulseDelay) > -1?'#048e7d':'#ff2c2c'}}> 
                                   
                                        <svg viewBox="0 0 36 36" className="circular-chart">
                                            <path className="circle-bg"
                                                d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                            />
                                            <path className={unixTimeStamp(val.pulseDelay) > -1?'circle':'circleTimeOut'}
                                                strokeDasharray={`${pulseTime(val.pulseDelay,val.deadline,val.pulseConTime,val.regdate)},100`}
                                                d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                            />
                                        </svg>



                                        <div>
                                            {unixTimeStamp(val.pulseDelay) < -999?-999:unixTimeStamp(val.pulseDelay) }분
                                            {/* {pulseTime(val.pulseDelay,val.deadline,val.pulseConTime,val.regdate)} */}
                                        </div>
                                </span>}


                            </span>:<></>}

                            {/* {val.state==='2'?
                            <span className="order-pick">배정-{val.deliverymanName}</span>:<></>}

                            {val.state==='3'?
                            <span className="order-delivery">배달-{val.deliverymanName}</span>:<></>} */}
 

                               
                        </div>
                                
                        {/* </Box> */}
                    </div> ))}

                    <div style={{height:0.1}}/>
                </div>
            );
        })

        const OrderDetail = observer(()=>{

            const [mapOn,setMapOn] = useState(false);
            
            const handleReceptionClosed = (val) => {
                runInAction(()=>{
                    posez.order.ckOrderSrl='0';
                    posez.order.listCkState='noneCk';
                })
            }


            const getCancelMessage = (idx)=>{
                let title = ""
                switch(idx){
                    case 1:
                        title = "관리자에 의해 취소됨"
                        break
                    case 2:
                        title = "사장님에 의해 취소됨"
                        break
                    case 3:
                        title = "고객에 의해 취소됨"
                        break
                }
                return title
            }
        
            const handlePrintReciept = ()=>{
                var menu = JSON.parse(JSON.stringify(posez.order.ckOrderList.pulseMenu))
                menu.payment = posez.order.ckOrderList.payment
                menu.stoName = posez.order.ckOrderList.storeName
                menu.bizNumber = posez.order.ckOrderList.bizNumber
                menu.stoAddress = posez.order.ckOrderList.stoAddress
                menu.stoContact = posez.order.ckOrderList.stoContact
                menu.ceoName = posez.order.ckOrderList.ceoName
                menu.riderMemo = posez.order.ckOrderList.riderMemo
                menu.stoMemo = posez.order.ckOrderList.memo
                console.log(menu)
        
                if(Number(menu.totalCost) > 0 ){
                    printws.webSocket.send(JSON.stringify(menu))
                }else{
                    console.log('menu없음')
                }
            }
        
        
            return(

            <div className="orderDetail" >
                        
            {posez.order.comOrder? 
            <span>
                <div className='calculateCostBar'>
                    <span>
                        <div>판매총액</div>
                        <div >{(posez.complete.totalCost).toLocaleString()}원</div>
                    </span>

                    <span>
                        <div>선불결제</div>
                        <div >0원</div>
                    </span>

                    <span>
                        <div>현금결제</div>
                        <div >0원</div>
                    </span>

                    <span>
                        <div>카드결제</div>
                        <div >{(posez.complete.cardCost).toLocaleString()}원</div>
                    </span>

                </div>
 
            </span>
            :
            <span>
                
                <div className="right-top" >
                    <div>
                        <span>
                        {Number(posez.order.ckOrderList.pulseMenu.totalCost).toLocaleString()}원
                        ({posez.order.ckOrderList.payment})
                          
                        </span>
                        <span >
                            <img src={imgCancel} alt="noImg" onClick={()=>handleReceptionClosed()}/>
                        </span>
                    </div>

                 


                    {posez.order.ckOrderList.pulseSrl > 0 ?
                    <div>
                        <button onClick={()=>posez.order.ckOrderList.pulseState === '1000' || pageSort === '4' ?'':handleAccept()} 
                            style={{color:posez.order.ckOrderList.pulseState === '1000' || pageSort === '4' ?'#eeeeee':'#ee8432'}}>
                            {posez.order.ckOrderList.pulseState === '0000'?"접수":posez.order.ckOrderList.pulseState==='0001'?"완료":"완료"}
                        </button>
 

                        <button onClick={()=>posez.order.ckOrderList.pulseState !== '0000' ? '':setRefusalOpen(true)}
                            style={{color:posez.order.ckOrderList.pulseState !== '0000' ? '#eeeeee':''}}>거부</button>
                       
             
                       
                        {/* <button onClick={()=>mapOn?setMapOn(false):setMapOn(true)}>
                            {mapOn?'지도숨기기':'지도보기'}
                        </button> */}
                    </div>:""}

                    
                </div>
 
                
            
                
                {/* 오른쪽 중앙 */}
                <div className="right-middle">
                    
                    {mapOn?<Box  height={200} marginBottom="10px">
                        
                        <div id="map" style={{height:'100%'}}>
                            {/* <RenderAfterNavermapsLoaded
                                ncpClientId={'98hawzukne'} // 자신의 네이버 계정에서 발급받은 Client ID
                                error={<p>Maps Load Error</p>}
                                loading={<p>Maps Loading...</p>}>
                                <NaverMapAPI latitude={posez.order.latitude} longitude={posez.order.longitude} />
                            </RenderAfterNavermapsLoaded> */}
                        </div>
                    </Box>:''}
        
                    
                    

                    {pageSort==='4' && posez.order.ckOrderList.pulseFromCancel>0?
                    
                    <div>
                        <div className="orderContent">
                            <div style={{color:'#ff0000',fontWeight:700}}>

                                {console.log('선택목록',posez.order.ckOrderList)}
                                [{getCancelMessage(posez.order.ckOrderList.pulseFromCancel)}]

                            </div>
                        </div>
                        <div className="orderContent"  style={{color:'#ff0000'}}>
                            <div>· 취소일자</div>
                            <div>
                                {posez.order.ckOrderList.pulseCancelTime}
                            </div>
                        </div>
                        <div className="orderContent"  style={{color:'#ff0000'}}>
                            <div>· 취소사유</div>
                            <div>
                                {posez.cancelMessage[posez.order.ckOrderList.pulseCancel]}
                            </div>
                        </div>
                    </div>

                    :<></>}

                    <div>

                      
                        <div className="orderContent">
                            <div>· 거래일시</div>
                            <div>
                                {posez.order.ckOrderList.regdate}
                            </div>
                        </div>
                        <div className="orderContent" style={{color:pageSort==='4' && posez.order.ckOrderList.pulseFromCancel>0?'':'#ff0000'}}>
                            <div>· 사장님께</div>
                            <div>
                                {posez.order.ckOrderList.memo.length>0?posez.order.ckOrderList.memo:"-"}
                            </div>
                        </div>
                        <div className="orderContent">
                            <div>· 기사님께</div>
                            <div>
                                {posez.order.ckOrderList.riderMemo?.length>0?posez.order.ckOrderList.riderMemo:"-"}
                            </div>
                        </div>
                    </div>

                    <div className="orderContent">
                        <div>· 전화번호</div>
                        <div>
                            {posez.order.ckOrderList.cusContact?.length>0?posez.order.ckOrderList.cusContact:"-"}
                        </div>
                    </div>
                        
                    <div className="orderContent">
                        <div>· 배달주소</div>
                        <div>
                            {posez.order.ckOrderList.cusAddress?.length>0?posez.order.ckOrderList.cusAddress+posez.order.ckOrderList.cusOtherAddress:"-"}
                        </div>
                    </div>

                    
                    <div className='costBox'>
                        {posez.order.ckOrderList.pulseMenu.menuList.map((val,idx)=>
                            <div className="receipt-item" key={idx} >
                                
                                    <div className="orderContent" style={{fontWeight:700,marginTop:15,alignItems:'center'}}>
                                        <div style={{whiteSpace:'pre-line',minWidth:'auto',textAlign:'left'}}>{val.menuName} x {val.menuQuantity}개</div>
                                    
                                        <div className='dottedline'></div>
                                        <div>
                                            {Number(val.menuTotalCost).toLocaleString()}원
                                        </div>
                                    </div>
                                    <div className="orderContent">
                                        <div> └ {val.menuDefaultOption.length>0?val.menuDefaultOption:'기본'}</div>
                                    
                                        <div>
                                            {Number(val.menuCost).toLocaleString()}원
                                        </div>
                                    </div> 
                            </div>
                        )}
                        
                        <div className="orderContent" style={{marginTop:25}}>
                            <div>· 주문금액</div>
                            <div>
                            {Number(posez.order.ckOrderList.pulseMenu.orderCost).toLocaleString()}원
                            </div>
                        </div>

                        <div className="orderContent">
                            <div>· 배달요금</div>
                            <div>
                            +{Number(posez.order.ckOrderList.pulseMenu.deliveryCost).toLocaleString()}원
                            </div>
                        </div>

                        <div className="orderContent">
                            <div>· 할인금액</div>
                            <div>
                            -{Number(posez.order.ckOrderList.pulseMenu.discountCost).toLocaleString()}원
                            </div>
                        </div>
                        
                    </div> 

                </div>
        
        
                {/* 오른쪽 하단 */}
 
        
                <div className="right-bottom">
                    {/* <button onClick={()=>handleSetOrderModify()}>주문서 수정</button> */}
               
                    <button onClick={()=>handleSetOrderModify()} style={{color:posez.order.ckOrderList.pulseState==='0001'?'#ee8432':'#eeeeee'}} >배달출발</button>
                    <button onClick={()=>handlePrintReciept()} >주문서 출력</button>
                    <button >영수증 출력</button>
                </div>
            </span>}
              
            </div>
        );
    })
    
  

    return(
    <div className='mainLayout'>
        <div className='topLayout'>
            <span>
                <div className='topLeft'>
                    <img src={imgLogo} alt="logo" />
                    <div> / {wsconfig.connection?'통신중':'통신장애'} / {printws.connection?'프린터연결됨':'프린터 연결 안됨'}</div>
                </div>
                <div className='topRight'> 
                        {/* <img src={imgGraph}  alt="pos" onClick={()=>history.push('/newOrder')}/> */}
                        {/* <img src={imgGraph} alt="graph" onClick={()=>history.push('/Calculate')}/> */}
                        <img src={imgOnOff} alt="graph"  onClick={()=>handleOnOff()}/>
          
                        {/* <img src={imgChat} alt="chat" onClick={()=>history.push('/posez/chat')}/> */}
                        <img src={imgSetting} alt="setting" onClick={()=>setSetting(true)}/> 
                </div>
            </span>
            
            <span>
              
                <div className='topLeft'>
                    {sortSrl.result.map((val,idx)=>
                        
                        <div key={idx} onClick={()=>handlePageSort(val.srl)}
                             className={pageSort===val.srl ? val.srl==='0'? 'rbOn0' : val.srl==='1'? 'rbOn1' : val.srl==='4'? 'rbOn2' : 'rbOn3':'rbOff'}>

                            {val.srl==='0'?
                                <div className='orderBtn'>
                                    <span>주문</span>
                                    <div>{posez.order.orderList.filter(vval=>vval.pulseState==='0000').length}</div>
                                </div>

                            :val.srl==='1'?
                                <div className='orderBtn'>
                                    <span>진행</span>
                                    <div>{posez.order.orderList.filter(vval=>vval.pulseState==='0001').length}</div>
                                </div> 
                                :val.srl==='4'?
                                <div className='orderBtn'>
                                    <span>완료</span>
                                    <div>-</div>
                                </div>
                                :val.srl==='5'?
                                <div className='orderBtn'>
                                    <span>매출</span>
                                    <div>-</div>
                                </div>  
                            :''}

                        </div> 
                    )}
                    
                </div>

                <div className='topRight' style={{borderBottom:'1px solid #cccccc',height:65,padding:'3px 20px 0px 20px'}}>
                    {pageSort==='4'?

                    
                    <span className='selectDateBox'>
                        <span className={select==='month'?'selectDateOn':'selectDateOff'} style={{marginRight:3}} onClick={()=>handleDateChange(refMonth.current.value,'month')}> 
                            <input type="month" id="input_month" onChange={()=>handleDateChange(refMonth.current.value,'month')} ref={refMonth} ></input>
                        </span>
                        <span className={select==='date'?'selectDateOn':'selectDateOff'} style={{marginLeft:3}} onClick={()=>handleDateChange(refDate.current.value,'date')}>
                            <input type="date" id="input_date"  onChange={()=>handleDateChange(refDate.current.value,'date')} ref={refDate} ></input>
                        </span>
                        
                     
                    </span>:
                    pageSort==='5'?
                    <span className='selectDateBox'>
                        <span className='selectDateOn' style={{marginRight:3}} onClick={()=>setDialogYear(true)}> 
                            <div>{ckYear}</div>
                            <div style={{paddingLeft:40,fontSize:11,color:'#5d5d5d'}}>▼</div>
                        </span>
                        <span className='selectDateOn' style={{marginLeft:3}} onClick={()=>setDialogQuarter(true)}>
                            <div>{ckQuarter}</div>
                            <div style={{paddingLeft:40,fontSize:11,color:'#5d5d5d'}}>▼</div>
                        </span>
                    </span>
                    :<></>}
                </div>
            </span>
        </div>
        
        <div style={{height:130,minHeight:130,maxHeight:130}}/>
        
        <div className='centerLayout'>
            {pageSort<5?
                <>
                    <div className='centerLeft'> 
                        <OrderList/>
                    </div>
                    <div className='centerRight'> 
                        {Number(posez.order.ckOrderSrl)>0 || posez.order.comOrder?<OrderDetail/>:<></>}
                    </div>
                </>
            :<Calculate/>}
        </div>
        

        <Dialog open={setting} onClick={()=>setSetting(false)}>
            <div className="dialog-setting" onClick={(e)=>e.stopPropagation()}>
                <div>
                    <Box dispaly="flex" flex={1}  style={{fontWeight:'800',fontSize:'18px',marginLeft:'5px',color:'#5d5d5d'}}>설정</Box>
                    <img src={imgCancel} alt="noImg" onClick={()=>setSetting(false)} style={{width:'22px',height:'22px',padding:'5px'}}/>
                </div>
                <div>
                    <div>알림메시지</div>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleAlarmMsg(true)}
                    style={posezSetting.config.alarmMsg?btnOn:btnOff}>켜기</Button>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleAlarmMsg(false)}
                    style={!posezSetting.config.alarmMsg?btnOn:btnOff}>끄기</Button>
                </div>
                <div>
                    <div>알림소리</div>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleAlarmSound(true)}
                    style={posezSetting.config.alarmSound?btnOn:btnOff}>켜기</Button>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleAlarmSound(false)}
                    style={!posezSetting.config.alarmSound?btnOn:btnOff}>끄기</Button>
                </div>
                
                <div>
                    <div>프린터연결</div>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleConnectPrint(true)}
                    style={printws.connection?btnOn:btnOff}>켜기</Button>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleConnectPrint(false)}
                    style={!printws.connection?btnOn:btnOff}>끄기</Button>
                </div>
                <div>
                    <div>알림서버연결</div>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleConnectServer(true)}
                    style={wsconfig.connection?btnOn:btnOff}>켜기</Button>
                    <Button dispaly="flex" flex={1}  onClick={()=>handleConnectServer(false)}
                    style={!wsconfig.connection?btnOn:btnOff}>끄기</Button>
                </div>
                {/* <div onClick={()=>history.push({pathname:"/receiptSetting"})}>
                    영수증 출력 설정
                </div> */}
                {/* <Link to="/manager"> */}
                <div onClick={handleLogout}>
                    <div >로그아웃</div>
                    <Box display="flex" flex={1} justifyContent="flex-end" style={{color:'#969696'}}>ver {posezSetting.setting.version}</Box>
                </div>
                {/* </Link> */}
            </div>
        </Dialog>


        <Dialog open={onoff} onClick={()=>setOnOff(false)}>
            <div className="dialog-onoff" onClick={(e)=>e.stopPropagation()}>
                <span >
                    <Box dispaly="flex" flex={1}  style={{fontWeight:'800',fontSize:'18px',marginLeft:'5px',color:'#5d5d5d'}}>매장 운영 상태</Box>
                    <img src={imgCancel} alt="noImg" onClick={()=>setOnOff(false)} style={{width:'22px',height:'22px',padding:'5px'}}/>
                </span>
                 
                <div style={{height:70,minHeight:70}}/>

                {posez.storeList.list.map((val,idx)=>
                    <div key={idx}>
                        <div className='storeOnOff'>
                            <img className='onoffImg' src={val.open===1?imgOn:imgOff} alt=''/> {val.storeName}
                        </div>
                      
                        <Button dispaly="flex" flex={1} onClick={(e)=>handleSetOpen(val)}
                        style={val.open===1?btnOn:btnOff}>영업중</Button>
                        <Button dispaly="flex" flex={1} onClick={(e)=>handleSetClosed(val)}
                        style={val.open===1?btnOff:btnOn}>영업종료</Button>
                    </div>
                )}
               
                
                
               
            </div>
        </Dialog>


        <Dialog open={dialogYear} onClick={()=>setDialogYear(false)}>
            <div className="dialog-val" onClick={(e)=>e.stopPropagation()}>         
                <span onClick={()=>{setCkYear('2022');setDialogYear(false)}}>2022</span>     
            </div>
        </Dialog>

        <Dialog open={dialogQuarter} onClick={()=>setDialogQuarter(false)}>
            <div className="dialog-val" onClick={(e)=>e.stopPropagation()}>
                <span onClick={()=>{setCkQuarter('1분기');setDialogQuarter(false)}}>1분기</span>          
                <span onClick={()=>{setCkQuarter('2분기');setDialogQuarter(false)}}>2분기</span>     
                <span onClick={()=>{setCkQuarter('3분기');setDialogQuarter(false)}}>3분기</span>          
                <span onClick={()=>{setCkQuarter('4분기');setDialogQuarter(false)}}>4분기</span> 
            </div>
        </Dialog>

        


         {/* dialog accept  */}
         <div className={acceptOpen?"order-accept-dialog-on":"order-accept-dialog-off"} onClick={()=>setAcceptOpen(false)}>
                <div onClick={(e)=>{e.stopPropagation();}}>
                    <ul>
                        <li>
                            <span className={dMinute===20?"ckOnL":"ckOffL"} onClick={()=>handleSetMinute(20)}>20분</span>   <span className={dMinute===30?"ckOnR":"ckOffR"} onClick={()=>handleSetMinute(30)}>30분</span>
                        </li>
                        <li>
                            <span className={dMinute===40?"ckOnL":"ckOffL"} onClick={()=>handleSetMinute(40)}>40분</span>   <span className={dMinute===50?"ckOnR":"ckOffR"} onClick={()=>handleSetMinute(50)}>50분</span>
                        </li>
                        <li>
                            <span className={dMinute===60?"ckOnL":"ckOffL"} onClick={()=>handleSetMinute(60)}>60분</span>   <span className={dMinute===70?"ckOnR":"ckOffR"} onClick={()=>handleSetMinute(70)}>70분</span>
                        </li>
                        <li>
                            <span className={dMinute===80?"ckOnL":"ckOffL"} onClick={()=>handleSetMinute(80)}>80분</span>   <span className={dMinute===90?"ckOnR":"ckOffR"} onClick={()=>handleSetMinute(90)}>90분</span>
                        </li>
                    </ul>
                    <div>
                        <span onClick={()=>handleSetConfirm()}
                        ><span style={{color:'#ee8432'}}>{dMinute}분</span> 접수</span>
                        <span onClick={()=>setAcceptOpen(false)}>닫기</span>
                    </div>

                    {/* <div>
                        <span onClick={()=>handleSetAccept()}>접수</span>
                        <span>취소</span>
                    </div> */}
                </div>
            </div>
            



            {/* dialog refusal  */}
            <div className={refusalOpen?"order-accept-dialog-on":"order-accept-dialog-off"} onClick={()=>setRefusalOpen(false)}>
                <div onClick={(e)=>{e.stopPropagation();}}>
                
                    <ul>
                    
                        <li>
                            <span className={rReason===1?"ckOnL":"ckOffL"} onClick={()=>handleSetRReason(1)}>품절</span>   <span className={rReason===2?"ckOnR":"ckOffR"} onClick={()=>handleSetRReason(2)}>재료부족</span>
                        </li>
                        <li>
                            <span className={rReason===3?"ckOnL":"ckOffL"} onClick={()=>handleSetRReason(3)}>배달불가지역</span>   <span className={rReason===4?"ckOnR":"ckOffR"} onClick={()=>handleSetRReason(4)}>영업시간아님</span>
                        </li>
                        <li>
                            <span className={rReason===5?"ckOnL":"ckOffL"} onClick={()=>handleSetRReason(5)}>휴무일</span>   <span className={rReason===6?"ckOnR":"ckOffR"} onClick={()=>handleSetRReason(6)}>최소금액부족</span>
                        </li>
                        <li>
                            <span className={rReason===7?"ckOnL":"ckOffL"} onClick={()=>handleSetRReason(7)}>주문량폭주</span>   <span className={rReason===8?"ckOnR":"ckOffR"} onClick={()=>handleSetRReason(8)}>기타</span>
                        </li>


                    </ul>

                    
                    <div>
                            <span onClick={()=>handleSetRefusal()}>주문거부</span>
                            <span onClick={()=>setRefusalOpen(false)}>닫기</span>
                    </div>

                    {/* <div>
                        <span onClick={()=>handleSetAccept()}>접수</span>
                        <span>취소</span>
                    </div> */}
                </div>
            </div>
           {/* dialog complete  */}
           <div className={completeOpen?"order-accept-dialog-on":"order-accept-dialog-off"} onClick={()=>setCompleteOpen(false)}>
                <div onClick={(e)=>{e.stopPropagation();}}>
                    <ul>
                        <li>
                            <span className="ckL" onClick={()=>handleSetConfirm()}>완료</span>   
                            <span className="ckR" onClick={()=>setCompleteOpen(false)}>닫기</span>
                        </li>
                    </ul>
                </div>
            </div>





        <div  onClick={handleClosed} onTouchStart={handleClosed} >
            <Snackbar open={toast}   autoHideDuration={3000}   onClick={handleClosed}  onClose={handleClosed}  onTouchStart={handleClosed}  style={{height:'100%'}}>
                <div  style={{maxWidth:'80vw',backgroundColor:'#00000090',borderRadius:'25px',color:'#ffffff',paddingTop:'8px',paddingBottom:'8px',paddingLeft:'15px',paddingRight:'15px',}}>
                    {toastMsg}
                </div>
            </Snackbar>
        </div>


    </div>)
})




export default PosezOrderList